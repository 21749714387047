import { Component, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { SafeHtmlPipe, TranslatePipe } from '@amaris/lib-highway/pipes';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [NgClass, SafeHtmlPipe, TranslatePipe],
})

export class TooltipComponent implements OnInit {
  public tooltip: string = '';
  public position: 'top' | 'bottom' | 'left' | 'right' = 'right';

  constructor() {}

  ngOnInit(): void {}
}
